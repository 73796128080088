import React, { useState, useEffect } from "react";
import { FaTrash, FaUndo } from "react-icons/fa";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useComplicationData } from "../../context/ComplicationDataContext";
import AddNewComplicationEntrySlideOver from "./AddNewComplicationEntrySlideOver";
import { useLocation } from "../../context/LocationContext";
import { useImageViewer } from "../../context/ImageViewerContext";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useUser } from '../../context/UserContext';
const TableHeader = () => (
  <tr>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Depth
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Complication Type
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Complication Description
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Problem Resolution
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Time Taken
    </th>
  </tr>
);

const ComplicationData = () => {
  const { user } = useUser();
  const { data, updateData, handleDelete, handleRestore, handleAddNewEntry } = useComplicationData();
  const {
    setSelectedReference,
    setSelectedFieldData,
    setIsModalOpen,
    selectedFieldReference,
    setSelectedFieldReference,
    setTempFieldValue,
  } = useLocation();

  const { setIsSlideOverOpen, tempFieldValue,tempReference, newData} = useImageViewer();

  const [isAddNewEntrySlideOverOpen, setIsAddNewEntrySlideOverOpen] =
    useState(false);
  const [localAcceptedValues, setLocalAcceptedValues] = useState([]);
  const [localDeletedValues, setLocalDeletedValues] = useState([]);
  const [selectedField, setSelectedField] = useState(null); // Store selected field data
  const [selectedParent, setSelectedParent] = useState("");
  useEffect(() => {
    console.log("Data:",data);
    if(data) {
      setLocalAcceptedValues(Object.keys(data.acceptedValues || {}));
      setLocalDeletedValues(Object.keys(data.deletedValues || {})); 
    }
  }, [data]);


  useEffect(()=>{
    handleSave(tempFieldValue,tempReference);
  },[newData])

  const handleSave = async (newValue, newReference) => {
    if(!selectedParent) return; 
    setTempFieldValue(newValue);
    setSelectedReference(newReference);
    console.log("Handle Save:",newValue, newReference);
    const tempData = data?.acceptedValues;
    const currentDisplayValue = tempData?.[selectedParent]?.[selectedFieldReference]?.display.value || null;
    
    const newEntry = {
      [selectedParent]: {
        ...tempData?.[selectedParent],
        [selectedFieldReference]: {
          ...tempData?.[selectedParent]?.[selectedFieldReference],
          history: [
            {
              value: currentDisplayValue,
              reference: tempData?.[selectedParent]?.[selectedFieldReference]?.display.reference || null,
              user_id: tempData?.[selectedParent]?.[selectedFieldReference]?.display.user_id || user.email,
            },
            ...(tempData?.[selectedParent]?.[selectedFieldReference]?.history || []),
          ].slice(0, 2), // Keep only the last 2 entries
          display: {
            value: newValue,
            reference: newReference,
            user_id: user.email,
          },
        },
      }
    };

    console.log("After New Entry:",newEntry)
    const updatedData = {
      ...data,
      acceptedValues: {
        ...data.acceptedValues,
        ...newEntry,
      },
    };

    await updateData(updatedData);
    // setSelectedReference(null);
    // setSelectedFieldData(null);
    // setSelectedFieldReference(null);
    setIsModalOpen(true);
  };

  const handleFieldClick = (value, fieldData, reference, displayValue) => {
    console.log("HandleFieldData Complication:",value, fieldData,reference, displayValue);
    setSelectedParent(value);
    setSelectedFieldData(fieldData);
    setSelectedFieldReference(reference);
    setSelectedReference(displayValue);
    setSelectedField(fieldData);
    setIsSlideOverOpen(true);
  };


  const getFieldNameLabel = (fieldName, fieldData) => {
      if(fieldData?.[fieldName]?.history?.length > 0) {
        return <h3 style={{color : 'green'}}>{fieldData?.[fieldName]?.display.value}</h3>
      }
      return <h3>{fieldData?.[fieldName]?.display?.value || 'N/A'}</h3>;
  }

  const renderTableRows = (values, isDeleted) => {
    if (!values) return null;
    return values.map((depth) => {
      const fieldData =
        data?.acceptedValues?.[depth] || data?.deletedValues?.[depth];
      if (!fieldData) return null;

      return (
        <tr key={depth}>
          <td
            className="px-4 py-2 "
            // onClick={() => handleFieldClick(depth, fieldData, "depth", depth)}
          >
            {depth}
          </td>
          <td
            className="px-4 py-2 "
            onClick={() =>
              handleFieldClick(
                depth,
                fieldData.complication_type,
                "complication_type",
                fieldData.complication_type?.display?.value
              )
            }
          >
            { getFieldNameLabel("complication_type", fieldData)|| "N/A"}
          </td>
          <td
            className="px-4 py-2 whitespace-pre-line break-words"
            onClick={() =>
              handleFieldClick(
                depth,
                fieldData.complication_description,
                "complication_description",
                fieldData.complication_description?.display?.value
              )
            }
          >
            { getFieldNameLabel("complication_description", fieldData) || "N/A"}
          </td>
          <td
            className="px-4 py-2 whitespace-pre-wrap break-words"
            onClick={() =>
              handleFieldClick(
                depth,
                fieldData.complication_problem_resolution,
                "complication_problem_resolution",
                fieldData.complication_problem_resolution?.display?.value
              )
            }
          >
            { getFieldNameLabel("complication_problem_resolution", fieldData) || "N/A"}
          </td>
          <td
            className="p-4 text-center flex flex-row justify-between items-center whitespace-pre-wrap break-words"
            onClick={() =>
              handleFieldClick(
                depth,
                fieldData.complication_time_taken,
                "complication_time_taken",
                fieldData.complication_time_taken?.display?.value
              )
            }
          >
            <span className="text-left">
              { getFieldNameLabel("complication_time_taken", fieldData) || "N/A"}
            </span>
            <div className="ml-2">
              {!isDeleted && (
                <>
                  <button
                    data-tooltip-id="trashTooltip"
                    data-tooltip-content="Delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(depth);
                    }}
                    className="text-red-500"
                  >
                    <FaTrash />
                  </button>
                  <ReactTooltip id="trashTooltip" />
                </>
              )}
              {isDeleted && (
                <>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRestore(depth);
                    }}
                    className="text-green-500"
                    data-tooltip-id="restoreTooltip"
                    data-tooltip-content="Restore"
                  >
                    <FaUndo />
                  </button>
                  <ReactTooltip id="restoreTooltip" />
                </>
              )}
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="flex flex-col justify-between">
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-bold mb-4">Accepted Values</h2>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 divide-y divide-gray-200">
          <thead>
            <TableHeader />
          </thead>
          <tbody className="divide-y divide-gray-200">
            {renderTableRows(localAcceptedValues, false)}
          </tbody>
        </table>
        <>
          <button
            data-tooltip-id="plusToolTip"
            data-tooltip-content="Add New Data"
            className="bg-blue-500 text-white px-4 py-2 rounded flex items-center justify-center mt-2"
            onClick={() => setIsAddNewEntrySlideOverOpen(true)}
          >
            <PlusIcon className="w-4 h-4" />
          </button>
          <ReactTooltip id="plusToolTip" />
        </>
      </div>

      <div className="overflow-x-auto my-20">
        <h2 className="text-lg font-bold mb-4 text-gray-400">Deleted Values</h2>
        <table className="min-w-full bg-white border border-gray-200 divide-y divide-gray-200">
          <thead>
            <TableHeader />
          </thead>
          <tbody className="divide-y divide-gray-200">
            {renderTableRows(localDeletedValues, true)}
          </tbody>
        </table>
      </div>

      <AddNewComplicationEntrySlideOver
        isOpen={isAddNewEntrySlideOverOpen}
        onClose={() => setIsAddNewEntrySlideOverOpen(false)}
        onSubmit={handleAddNewEntry}
        data={data}
      />
    </div>
  );
};

export default ComplicationData;
