import React, { createContext, useState, useEffect, useContext, useMemo } from 'react';
import { useUser } from '../context/UserContext'; 

const TrajectoryDataContext = createContext();

export const TrajectoryDataProvider = ({ children }) => {
  const { user } = useUser();
  const [data, setData] = useState({});
  const [wellId, setWellId] = useState("");

  const getWellIdFromQueryString = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('well_id');
  };

  useEffect(() => {
    const well_id = getWellIdFromQueryString();
    if (well_id) {
      setWellId(well_id);
    }
  }, [window.location.search]);

  useEffect(() => {
    if (wellId) {
      const fetchData = async () => {
        try {
          const response = await fetch(`/v1/well/get-trajectory-data/${wellId}`, {
            method: "GET",
            credentials: 'include',
          });
          if (response) {
            const data = await response.json();
            console.log("data:", data);
            if (data && data.data) {
              setData(data.data);
            }
          }
        } catch (err) {
          console.log("Err while fetching", err);
          setData({});
        }
      };
      fetchData();
    } 
  }, [wellId]);

  const updateData = async (newData) => {
    try {
      const response = await fetch(`/v1/well/update-trajectory-data`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          well_id: wellId,
          trajectory_data: newData
        }),
        credentials: 'include'
      });

      if (response.ok) {
        const data = await response.json();
        if (data && data.data) {
          console.log("Trajectory data successfully updated:", data.data);
          setData(newData);
        }
      } else {
        console.error('Failed to update Trajectory data', response.statusText);
      }
    } catch (err) {
      console.error('Error updating Trajectory data', err);
    }
  };

  const handleAddNewEntry = (formData) => {
    console.log("formData trajectory", formData, data);
    if (formData.MD === undefined) return;

    const newEntry = {};

    // Check if formData has the necessary fields
    if (formData?.MD) {
      newEntry[formData.MD] = {
        inclination: {
          options: {},
          history: [],
          display: {
            value: formData?.inclination || null,
            reference: formData?.inclination_ref || null,
            user_id: user?.email || null, // Safely access user.email
          },
        },
        azimuth: {
          options: {},
          history: [],
          display: {
            value: formData?.azimuth || null,
            reference: formData?.azimuth_ref || null,
            user_id: user?.email || null, // Safely access user.email
          },
        },
      };
    
      // Handle inclination data if available in acceptedValues
      if (data?.acceptedValues?.[formData.MD]?.inclination) {
        if(formData.inclination) {
          newEntry[formData.MD].inclination.options[formData.inclination] = {
            count: 1,
            reference: formData.inclination_ref || null,
          };
          newEntry[formData.MD].inclination.history = [
            {
              value: data.acceptedValues[formData.MD]?.inclination?.display?.value || null,
              reference: data.acceptedValues[formData.MD]?.inclination?.display?.reference || null,
              user_id: data.acceptedValues[formData.MD]?.inclination?.display?.user_id || null,
            },
            ...(data.acceptedValues[formData.MD]?.inclination?.history || []),
          ].slice(0, 3); // Keep only the last 3 entries
        }

      }
    
      // Handle azimuth data if available in acceptedValues
      if (data?.acceptedValues?.[formData.MD]?.azimuth && formData.azimuth) {
        newEntry[formData.MD].azimuth.options[formData.azimuth] = {
          count: 1,
          reference: formData.azimuth_ref || null,
        };
        newEntry[formData.MD].azimuth.history = [
          {
            value: data.acceptedValues[formData.MD]?.azimuth?.display?.value || null,
            reference: data.acceptedValues[formData.MD]?.azimuth?.display?.reference || null,
            user_id: data.acceptedValues[formData.MD]?.azimuth?.display?.user_id || null,
          },
          ...(data.acceptedValues[formData.MD]?.azimuth?.history || []),
        ].slice(0, 3); // Keep only the last 3 entries
      }
    }
    
    console.log("New Entry:",newEntry);

    const updatedData = {
      ...data,
      acceptedValues: {
        ...data?.acceptedValues,
        ...newEntry,
      },
    };

    updateData(updatedData);
  };

  const handleDelete = (value) => {
    const updatedAcceptedValues = { ...data?.acceptedValues };
    const deletedValue = updatedAcceptedValues[value];

    if(!deletedValue) return;
    if(!deletedValue.history) {
      deletedValue.history = [];
    }

    const updatedDeletedValues = {
      ...data.deletedValues,
      [value]: {
        ...deletedValue,
        history: [
          {
            value: deletedValue,
            user_id: user.email,
            message : 'Deleted by '+user.email
          },
          ...deletedValue.history,
        ].slice(0, 3), // Keep only the last 3 entries
      },
    };


    delete updatedAcceptedValues[value];

    updateData({
      ...data,
      acceptedValues: updatedAcceptedValues,
      deletedValues: updatedDeletedValues,
    });
  };

  const handleRestore = (value) => {
    console.log("value",value);
    const updatedDeletedValues = { ...data?.deletedValues };
    const restoredValue = updatedDeletedValues[value];
    console.log("restoredValue",restoredValue);
    if(!restoredValue) return;
    if(!restoredValue.history) {
      restoredValue.history = [];
    }
    const updatedAcceptedValues = {
      ...data.acceptedValues,
      [value]: {
        ...restoredValue,
        history: [
          {
            value: restoredValue,
            user_id: user.email,
            message : 'Restored by '+user.email
          },
          ...restoredValue.history,
        ].slice(0, 3), // Keep only the last 3 entries
      },
    };

    console.log("restoredValue",restoredValue);
    delete updatedDeletedValues[value];

    updateData({
      ...data,
      acceptedValues: updatedAcceptedValues,
      deletedValues: updatedDeletedValues,
    });
  };

  const value = useMemo(() => ({
    data,
    updateData,
    handleAddNewEntry,
    handleDelete,
    handleRestore,
    setWellId
  }), [data]);

  return (
    <TrajectoryDataContext.Provider value={value}>
      {children}
    </TrajectoryDataContext.Provider>
  );
};

export const useTrajectoryData = () => {
  return useContext(TrajectoryDataContext);
};
