import React, { useState, useEffect } from "react";
import { FaTrash, FaUndo } from "react-icons/fa";
import { PlusIcon } from "@heroicons/react/24/solid";
import ConfirmationModal from "../ConfirmationModal2";
import { useData } from "../../context/DataContext";
import AddNewEntrySlideOver from "./AddNewEntrySlideOver";
import { useLocation } from "../../context/LocationContext";
import { useImageViewer } from "../../context/ImageViewerContext";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useUser } from '../../context/UserContext'; 

const fields = [
  "casing_size",
  "casing_type",
  "casing_grade",
  "casing_start_md",
  "casing_end_md",
  "casing_weight",
  "hole_end_md",
  "hole_size",
  "toc_md"
]

const TableHeader = () => (
  <tr>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Casing Type
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Hole Size
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Hole End Md
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Casing Size
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Casing Grade
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Casing Weight
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Casing Start Md
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Casing End Md
    </th>
    {/* <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Toc Md
    </th> */}
  </tr>
);

const HoleCasingData = () => {
  const { user } = useUser();
  const { data, updateData, handleSaveFromImageViewer } = useData();
  const {
    setSelectedFieldData,
    setSelectedReference,
    well,
    setIsModalOpen,
    setTempFieldValue,
  } = useLocation();

  const { tempFieldValue, tempReference,setIsSlideOverOpen , newData} = useImageViewer();
  const [selectedFieldReference, setSelectedFieldReference] = useState(null); // Ensure this is defined


  const [isAddNewEntrySlideOverOpen, setIsAddNewEntrySlideOverOpen] =
    useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [localAcceptedValues, setLocalAcceptedValues] = useState([]);
  const [localSuggestedValues, setLocalSuggestedValues] = useState([]);
  const [localDeletedValues, setLocalDeletedValues] = useState([]);
  const [suggestedValueToMove, setSuggestedValueToMove] = useState(null);
  const [selectedField, setSelectedField] = useState(null); // Store selected field data
  const [selectedParent, setSelectedParent] = useState("");
  useEffect(() => {
    setLocalAcceptedValues(
      Object.keys(data.acceptedValues || {}).map((val) => (val))
    );
    setLocalSuggestedValues(
      Object.keys(data.suggestedValues || {}).map((val) => (val))
    );
    setLocalDeletedValues(
      Object.keys(data.deletedValues || {}).map((val) => (val)) 
    );
  }, [data]);

  // useEffect(() =>{
  //   console.log("New data:",newData)
  //   if(!newData) return;
  //   console.log("data",data);
  //   console.log("newdata:",newData,data.acceptedValues);
  //   const updatedData = {
  //     ...data,
  //     acceptedValues: {...data.acceptedValues, [newData.newValue] : {...data.deletedValues[newData.newValue],restoredBy : 'UserId'}},
  //   }
  //   updateData(updatedData);
  // },[newData]);


  useEffect(() => {
    handleSave(tempFieldValue, tempReference);
  }, [newData]);

  const handleSave = async (newValue, newReference) => {
    // if (!newValue) return; 
    if(!selectedParent) return;
    setTempFieldValue(newValue);
    setSelectedReference(newReference);
    console.log("Handle Save:", newValue, newReference);

    const tempData = data?.acceptedValues;
    const currentDisplayValue = tempData?.[selectedParent]?.[selectedFieldReference]?.display.value || null;
    const newEntry = {
      [selectedParent]: {
        ...tempData?.[selectedParent],
        [selectedFieldReference]: {
          ...tempData?.[selectedParent]?.[selectedFieldReference],
          history: [
            {
              value: currentDisplayValue,
              reference: tempData?.[selectedParent]?.[selectedFieldReference]?.display.reference || null,
              user_id: tempData?.[selectedParent]?.[selectedFieldReference]?.display.user_id || user.email,
            },
            ...(tempData?.[selectedParent]?.[selectedFieldReference]?.history || []),
          ].slice(0, 2), // Keep only the last 2 entries
          display: {
            value: newValue,
            reference: newReference,
            user_id: user.email,
          },
        },
      }
    };


    console.log("After New Entry:", newEntry);
    const updatedData = {
      ...data,
      acceptedValues: {
        ...data?.acceptedValues,
        ...newEntry,
      },
    };

    await updateData(updatedData);

    setSelectedReference(null);
    setSelectedFieldData(null);
    setSelectedFieldReference(null);
    setIsModalOpen(true);
  };




  const handleDelete = (value) => {
    const updatedAcceptedValues = localAcceptedValues.filter(
      (val) => val !== value
    );
    setLocalAcceptedValues(updatedAcceptedValues);
    setLocalDeletedValues([...localDeletedValues,value]);

    console.log("Object deleted",{...data?.deletedValues, [value] : {...data?.acceptedValues[value],deletedBy : user.email}});
    const updatedData = {
      ...data,
      deletedValues: {...data?.deletedValues, [value] : {...data?.acceptedValues?.[value],deletedBy : user.email}},
    };

    delete updatedData?.acceptedValues?.[value];
    
    updateData(updatedData);
  };


  const handleRestore = (value) => {
    const updatedDeletedValues = localDeletedValues.filter(
      (val) => val !== value
    );
    setLocalDeletedValues(updatedDeletedValues);
    setLocalAcceptedValues([...localAcceptedValues, value]);

    console.log("Object restore",{...data?.deletedValues, [value] : {...data?.acceptedValues[value],deletedBy : user.email}});
    const updatedData = {
      ...data,
      acceptedValues: {...data?.acceptedValues, [value] : {...data?.deletedValues?.[value],restoredBy : user.email}},
    };

    delete updatedData?.deletedValues?.[value];
    updateData(updatedData);
  };

  const handleMoveSuggestedValue = (value) => {
    setIsConfirmationModalOpen(true);
    setSuggestedValueToMove(value);
  };

  const confirmMoveSuggestedValue = () => {
    if (suggestedValueToMove !== null) {
      setLocalAcceptedValues([...localAcceptedValues, suggestedValueToMove]);
      const updatedSuggestedValues = localSuggestedValues.filter(
        (val) => val !== suggestedValueToMove
      );
      setLocalSuggestedValues(updatedSuggestedValues);


      // const updatedData = {
      //   ...data,
      //   acceptedValues: [
      //     ...data.acceptedValues,
      //     { [suggestedValueToMove]: "userId", message: "restored BY" },
      //   ],
      //   suggestedValues: Object.keys(data.suggestedValues).reduce(
      //     (acc, key) => {
      //       if ((key) !== suggestedValueToMove) {
      //         acc[key] = data.suggestedValues[key];
      //       }
      //       return acc;
      //     },
      //     {}
      //   ),
      // };

      const updatedData = {
        ...data,
        acceptedValues: {...data?.acceptedValues, [suggestedValueToMove] : {...data?.suggestedValues?.[suggestedValueToMove]}},
      };
  
      delete updatedData?.suggestedValues?.[suggestedValueToMove];

      updateData(updatedData);
      setIsConfirmationModalOpen(false);
      setSuggestedValueToMove(null);
    }
  };

  const handleAddNewEntry = (formData) => {
    console.log("formData",formData,data);
    if(!formData.casing_size) return;
    
    const newEntry = fields.reduce((acc, field) => {
      acc[formData.casing_size] = {
        ...acc[formData.casing_size],
        [field]: {
          options: {},
          history: [],
          display: {
            value: formData[field],
            reference: { pdf_id: formData?.[`${field}_ref`]?.pdf_id || '', page_number:  formData?.[`${field}_ref`]?.page_number || "" },
            user_id: user.email
          }
        }
      };
      return acc;
    }, {});

    console.log("newEntry",newEntry);

    console.log("data:",data);
    const updatedData = {
      ...data,
      acceptedValues: {
        ...data?.acceptedValues,
        ...newEntry,
      },
    };

    console.log("updatedData",updatedData);
    updateData(updatedData);
  };

  const getFieldNameLabel = (fieldName, fieldData) => {
      if(fieldData?.[fieldName]?.history?.length > 0) {
        return <h3 style={{color : 'green'}}>{fieldData?.[fieldName]?.display.value}</h3>
      }
      return <h3>{fieldData?.[fieldName]?.display?.value || 'N/A'}</h3>;
  }
  const handleFieldClick = (value, fieldData, reference, displayValue) => {
    console.log("HandleFieldData:", value, fieldData, reference, displayValue);
    setSelectedParent(value);
    setSelectedFieldData(fieldData);
    setSelectedFieldReference(reference);
    setSelectedReference(displayValue);
    setSelectedField(fieldData);
    setIsSlideOverOpen(true);
  };

  const renderTableRows = (data, values, isSuggested, isDeleted) => {
    if(!data) return null;
    if (!values) return null;
    return values.map((value) => {
      if(value === null || value === undefined) return null;
      const fieldData = data[value];
      if (!fieldData) return null;
      // if(!fieldData.casing_type){
      //   console.log("Error: casing_type not present");
      //   return null;
      // } 

      return (
        <tr
          key={value}
          onClick={() => (isSuggested ? handleMoveSuggestedValue(value) : null)}
        >
          <td
            className="px-4 py-2"
            onClick={() =>
              !isSuggested &&
              handleFieldClick(
                value,
                fieldData.casing_type,
                "casing_type",
                fieldData?.casing_type?.display?.value
              )
            }
          >
            {fieldData.casing_type
              ? getFieldNameLabel("casing_type", fieldData)
              : "N/A"}
          </td>
          <td
            className="px-4 py-2 text-center"
            onClick={() =>
              !isSuggested &&
              handleFieldClick(
                value,
                fieldData.hole_size,
                "hole_size",
                fieldData.hole_size.display.value
              )
            }
          >
            {fieldData.hole_size ? getFieldNameLabel("hole_size", fieldData) : "N/A"}
          </td>
          <td
            className="px-4 py-2 text-center"
            onClick={() =>
              !isSuggested &&
              handleFieldClick(
                value,
                fieldData.hole_end_md,
                "hole_end_md",
                fieldData.hole_end_md.display.value
              )
            }
          >
            {fieldData.hole_end_md
              ?  getFieldNameLabel("hole_end_md", fieldData)
              : "N/A"}
          </td>
          <td
            className="px-4 py-2 text-center"
            onClick={() =>
              !isSuggested && handleFieldClick(value,fieldData, "casing_size", value)
            }
          >
            {value ? value : "N/A"}
          </td>
          <td
            className="px-4 py-2 text-center"
            onClick={() =>
              !isSuggested &&
              handleFieldClick(
                value,
                fieldData.casing_grade,
                "casing_grade",
                fieldData.casing_grade.display.value
              )
            }
          >
            {fieldData.casing_grade
              ?  getFieldNameLabel("casing_grade", fieldData)
              : "N/A"}
          </td>
          <td
            className="px-4 py-2 text-center"
            onClick={() =>
              !isSuggested &&
              handleFieldClick(
                value,
                fieldData.casing_weight,
                "casing_weight",
                fieldData.casing_weight.display.value
              )
            }
          >
            {fieldData.casing_weight
              ?  getFieldNameLabel("casing_weight", fieldData)
              : "N/A"}
          </td>
          <td
            className="px-4 py-2 text-center"
            onClick={() =>
              !isSuggested &&
              handleFieldClick(
                value,
                fieldData.casing_start_md,
                "casing_start_md",
                fieldData.casing_start_md.display.value
              )
            }
          >
            {fieldData.casing_start_md
              ?  getFieldNameLabel("casing_start_md", fieldData)
              : "N/A"}
          </td>
          <td
            className="px-4 py-2 text-center"
            onClick={() =>
              !isSuggested &&
              handleFieldClick(
                value,
                fieldData.casing_end_md,
                "casing_end_md",
                fieldData.casing_end_md.display.value
              )
            }
          >
            {fieldData.casing_end_md
              ?  getFieldNameLabel("casing_end_md", fieldData)
              : "N/A"}
          </td>
          <td
            className="p-4 text-center flex flex-row justify-between items-center"
            onClick={() =>
              !isSuggested &&
              handleFieldClick(
                value,
                fieldData.toc_md,
                "toc_md",
                fieldData.toc_md.display.value
              )
            }
          >
            {/* <span className="text-center">
              {fieldData.toc_md ? fieldData.toc_md.display.value : "N/A"}
            </span> */}
            <div className="ml-2">
              {!isSuggested && !isDeleted && (
                <>
                  <button
                    data-tooltip-id="trashTooltip"
                    data-tooltip-content="Delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(value);
                    }}
                    className="text-red-500"
                  >
                    <FaTrash />
                  </button>
                  <ReactTooltip id="trashTooltip" />
                </>
              )}
              {isDeleted && (
                <>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRestore(value);
                    }}
                    className="text-green-500"
                    data-tooltip-id="restoreTooltip"
                    data-tooltip-content="Restore"
                  >
                    <FaUndo />
                  </button>
                  <ReactTooltip id="restoreTooltip" />
                </>
              )}
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="flex flex-col justify-between">
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-bold mb-4">Accepted Values</h2>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 divide-y divide-gray-200">
          <thead>
            <TableHeader />
          </thead>
          <tbody className="divide-y divide-gray-200">
            {renderTableRows(data?.['acceptedValues'],localAcceptedValues, false, false)}
          </tbody>
        </table>
        <>
          <button
            data-tooltip-id="plusToolTip"
            data-tooltip-content="Add New Data"
            className="bg-blue-500 text-white px-4 py-2 rounded flex items-center justify-center mt-2"
            onClick={() => setIsAddNewEntrySlideOverOpen(true)}
          >
            <PlusIcon className="w-4 h-4" />
          </button>
          <ReactTooltip id="plusToolTip" />
        </>
      </div>

      <div className="overflow-x-auto mt-20">
        <h2 className="text-lg font-bold mb-4 text-gray-400">
          Suggested Values
        </h2>
        <table className="min-w-full bg-white border border-gray-200 divide-y divide-gray-200">
          <thead>
            <TableHeader />
          </thead>
          <tbody className="divide-y divide-gray-200">
            {renderTableRows(data?.['suggestedValues'],localSuggestedValues, true, false)}
          </tbody>
        </table>
      </div>

      <div className="overflow-x-auto my-20">
        <h2 className="text-lg font-bold mb-4 text-gray-400">Deleted Values</h2>
        <table className="min-w-full bg-white border border-gray-200 divide-y divide-gray-200">
          <thead>
            <TableHeader />
          </thead>
          <tbody className="divide-y divide-gray-200">
            {renderTableRows(data?.['deletedValues'],localDeletedValues, false, true)}
          </tbody>
        </table>
      </div>

      <AddNewEntrySlideOver
        isOpen={isAddNewEntrySlideOverOpen}
        onClose={() => setIsAddNewEntrySlideOverOpen(false)}
        onSubmit={handleAddNewEntry}
        data={data}
      />

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        onConfirm={confirmMoveSuggestedValue}
        value={suggestedValueToMove}
      />
    </div>
  );
};

export default HoleCasingData;
