import React, { createContext, useState, useContext, useMemo, useEffect } from 'react';
import { useUser } from '../context/UserContext'; 

// Create the context
const DataContext = createContext();

// Create a provider component
export const DataProvider = ({ children }) => {
  // const [data, setData] = useState({
  //   depth_unit: {
  //     ft: { count: 24, reference: 104 },
  //     feet: { count: 4, reference: 20 },
  //     inch: { count: 1, reference: 25 },
  //     m: { count: 3, reference: 84 },
  //   },
  //   diameter_unit: {
  //     inch: { count: 26, reference: 103 },
  //     in: { count: 4, reference: 104 },
  //     mm: { count: 1, reference: 50 },
  //   },
  //   casing_weight_unit: {
  //     ppf: { count: 28, reference: 104 },
  //   },
  //   data: {
  //     8: {
  //       count: {
  //         options: {},
  //         history: [],
  //         display: { value: 4, reference: null, user_id: "user132" },
  //       },
  //       rank: {
  //         options: {},
  //         history: [],
  //         display: { value: 5, reference: null, user_id: "user132" },
  //       },
  //       casing_type: {
  //         options: {
  //           "Surface casing": { count: 2, reference: 41 },
  //           "Production casing": { count: 1, reference: 35 },
  //           "Drilling liner": { count: 1, reference: 38 },
  //         },
  //         history: [],
  //         display: { value: "Surface casing", reference: 41, user_id: "user132" },
  //       },
  //       hole_size: {
  //         options: {},
  //         history: [],
  //         display: { value: 8, reference: 41, user_id: "user132" },
  //       },
  //       hole_end_md: {
  //         options: {
  //           1783: { count: 1, reference: 32 },
  //           2296: { count: 1, reference: 35 },
  //           2827: { count: 1, reference: 38 },
  //           3028: { count: 1, reference: 41 },
  //         },
  //         history: [],
  //         display: { value: 1783, reference: 32, user_id: "user132" },
  //       },
  //       casing_grade: {
  //         options: [],
  //         history: [],
  //         display: { value: null, reference: null, user_id: "user132" },
  //       },
  //       casing_weight: {
  //         options: [],
  //         history: [],
  //         display: { value: null, reference: null, user_id: "user132" },
  //       },
  //       casing_start_md: {
  //         options: {
  //           1702: { count: 1, reference: 32 },
  //           2206: { count: 1, reference: 35 },
  //           3016: { count: 1, reference: 41 },
  //         },
  //         history: [],
  //         display: { value: 1702, reference: 32, user_id: "user132" },
  //       },
  //       casing_end_md: {
  //         options: {
  //           1783: { count: 1, reference: 32 },
  //           2296: { count: 1, reference: 35 },
  //           2827: { count: 1, reference: 38 },
  //           3028: { count: 1, reference: 41 },
  //         },
  //         history: [],
  //         display: { value: 1783, reference: 32, user_id: "user132" },
  //       },
  //       toc_md: {
  //         options: {},
  //         history: [],
  //         display: { value: null, reference: null, user_id: "user132" },
  //       },
  //     },
  //     10: {
  //       count: {
  //         options: {},
  //         history: [],
  //         display: { value: 2, reference: null, user_id: "user132" },
  //       },
  //       rank: {
  //         options: {},
  //         history: [],
  //         display: { value: 2, reference: null, user_id: "user132" },
  //       },
  //       casing_type: {
  //         options: {
  //           "Open hole": { count: 1, reference: 23 },
  //           "unknown": { count: 1, reference: 25 },
  //         },
  //         history: [],
  //         display: { value: "Open hole", reference: 23, user_id: "user132" },
  //       },
  //       hole_size: {
  //         options: {},
  //         history: [],
  //         display: { value: null, reference: null, user_id: "user132" },
  //       },
  //       hole_end_md: {
  //         options: {},
  //         history: [],
  //         display: { value: 2132, reference: 23, user_id: "user132" },
  //       },
  //       casing_grade: {
  //         options: [],
  //         history: [],
  //         display: { value: null, reference: null, user_id: "user132" },
  //       },
  //       casing_weight: {
  //         options: [],
  //         history: [],
  //         display: { value: null, reference: null, user_id: "user132" },
  //       },
  //       casing_start_md: {
  //         options: {},
  //         history: [],
  //         display: { value: null, reference: null, user_id: "user132" },
  //       },
  //       casing_end_md: {
  //         options: {},
  //         history: [],
  //         display: { value: null, reference: null, user_id: "user132" },
  //       },
  //       toc_md: {
  //         options: {},
  //         history: [],
  //         display: { value: null, reference: null, user_id: "user132" },
  //       },
  //     },
  //     20: {
  //       count: {
  //         options: {},
  //         history: [],
  //         display: { value: 1, reference: null, user_id: "user132" },
  //       },
  //       rank: {
  //         options: {},
  //         history: [],
  //         display: { value: 6, reference: null, user_id: "user132" },
  //       },
  //       casing_type: {
  //         options: {
  //           "Conductor casing": { count: 1, reference: 10 },
  //         },
  //         history: [],
  //         display: { value: "Conductor casing", reference: 10, user_id: "user132" },
  //       },
  //       hole_size: {
  //         options: {},
  //         history: [],
  //         display: { value: 24, reference: 10, user_id: "user132" },
  //       },
  //       hole_end_md: {
  //         options: {},
  //         history: [],
  //         display: { value: 17, reference: 10, user_id: "user132" },
  //       },
  //       casing_grade: {
  //         options: [],
  //         history: [],
  //         display: { value: null, reference: null, user_id: "user132" },
  //       },
  //       casing_weight: {
  //         options: [],
  //         history: [],
  //         display: { value: null, reference: null, user_id: "user132" },
  //       },
  //       casing_start_md: {
  //         options: {},
  //         history: [],
  //         display: { value: 0, reference: 10, user_id: "user132" },
  //       },
  //       casing_end_md: {
  //         options: {},
  //         history: [],
  //         display: { value: 17, reference: 10, user_id: "user132" },
  //       },
  //       toc_md: {
  //         options: {},
  //         history: [],
  //         display: { value: 17, reference: 10, user_id: "user132" },
  //       },
  //     },
  //     13.375: {
  //       count: {
  //         options: { 7: { count: 7, reference: null } },
  //         history: [],
  //         display: { value: 7, reference: null, user_id: "user132" },
  //       },
  //       rank: {
  //         options: { 4: { count: 4, reference: null } },
  //         history: [],
  //         display: { value: 4, reference: null, user_id: "user132" },
  //       },
  //       casing_type: {
  //         options: {
  //           "Surface casing": { count: 2, reference: 10 },
  //           "Unknown casing type": { count: 1, reference: 9 },
  //           "Surface Plug": { count: 1, reference: 11 },
  //           "Across Casing Shoe": { count: 1, reference: 11 },
  //           "Abandonment Plug": { count: 2, reference: 11 },
  //         },
  //         history: [],
  //         display: { value: "Surface casing", reference: 10, user_id: "user132" },
  //       },
  //       hole_size: {
  //         options: { 17: { count: 1, reference: 10 } },
  //         history: [],
  //         display: { value: 17, reference: 10, user_id: "user132" },
  //       },
  //       hole_end_md: {
  //         options: {
  //           600: { count: 1, reference: 11 },
  //           650: { count: 2, reference: 10 },
  //           1700: { count: 1, reference: 11 },
  //           2185: { count: 1, reference: 11 },
  //         },
  //         history: [],
  //         display: { value: 600, reference: 11, user_id: "user132" },
  //       },
  //       casing_grade: {
  //         options: [],
  //         history: [],
  //         display: { value: "H40", reference: 10, user_id: "user132" },
  //       },
  //       casing_weight: {
  //         options: [],
  //         history: [],
  //         display: { value: 48, reference: 10, user_id: "user132" },
  //       },
  //       casing_start_md: {
  //         options: {
  //           0: { count: 5, reference: 11 },
  //           17: { count: 1, reference: 10 },
  //         },
  //         history: [],
  //         display: { value: 0, reference: 11, user_id: "user132" },
  //       },
  //       casing_end_md: {
  //         options: {
  //           600: { count: 1, reference: 11 },
  //           650: { count: 2, reference: 10 },
  //           1700: { count: 1, reference: 11 },
  //           2185: { count: 1, reference: 11 },
  //         },
  //         history: [],
  //         display: { value: 600, reference: 11, user_id: "user132" },
  //       },
  //       toc_md: {
  //         options: {
  //           600: { count: 1, reference: 11 },
  //           616: { count: 1, reference: 10 },
  //           1700: { count: 1, reference: 11 },
  //           2185: { count: 1, reference: 11 },
  //         },
  //         history: [],
  //         display: { value: 600, reference: 11, user_id: "user132" },
  //       },
  //     },
  //   },
  //   acceptedValues: [{ 13.375: "userId", message: "restored BY" }],
  //   suggestedValues: { 20: true },
  //   deletedValues: [],
  // });


  
  const { user } = useUser();
  const [data, setData] = useState({});
  const [wellId, setWellId] = useState("");

  const getWellIdFromQueryString = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('well_id');
  };


  useEffect(() => {
    const well_id = getWellIdFromQueryString();
    if (well_id) {
      setWellId(well_id);
    }
  }, [window.location.search]);

  useEffect(() => {
    if(wellId) {
      const fetchData = async () => {
        try {
          const response = await fetch(`/v1/well/get-hole-casing-data/${wellId}`,{
            method: "GET",
            credentials: 'include',
          });
          if(response) {
            const data = await response.json();
            console.log("data:",data);
            if(data && data.data) {
              setData(data.data);
            }
          }
        } catch (err) {
          // handle no data found case
          console.log("Err while fetching",err);
          setData({})
        }
      };
      fetchData();
    } 
  }, [wellId]);



  const updateData = async (newData) => {
    try {
      const response = await fetch(`/v1/well/update-hole-casing-data`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          well_id: wellId,
          hole_casing_data: newData
        }),
        credentials: 'include'
      });

      if (response.ok) {
        const data = await response.json();
        if (data && data.data) {
          console.log("HoleCasing data successfully updated:", data.data);
          setData(newData);
        }
      } else {
        console.error('Failed to update Hole Casing data', response.statusText);
      }
    } catch (err) {
      console.error('Error updating Hole Casing data', err);
    }
  };


  const handleSaveFromImageViewer = (casingSize, field, newValue, newReference) => {
    console.log("handleSaveFromImageViewer",data)
    setData((prevState) => {
      const fieldData = { ...prevState.data[casingSize][field] };
      const isCustomValue = !fieldData.options[newValue];

      const isSameDisplayValueDifferentReference =
        fieldData.display.value === newValue &&
        fieldData.display.reference !== newReference;

      let newDisplay;

      if (isCustomValue || isSameDisplayValueDifferentReference) {
        newDisplay = { count: 1, reference: newReference };
      } else {
        newDisplay = fieldData.options[newValue];
        delete fieldData.options[newValue];
      }

      fieldData.history.push({
        value: fieldData.display.value,
        reference: fieldData.display.reference,
        user_id: fieldData.display.user_id,
      });

      if (!isSameDisplayValueDifferentReference) {
        fieldData.options[fieldData.display.value] = {
          count: 1,
          reference: fieldData.display.reference,
        };
      }

      fieldData.display = {
        value: newValue,
        reference: newDisplay.reference,
        user_id: fieldData.display.user_id,
      };

      return {
        ...prevState,
        data: {
          ...prevState.data,
          [casingSize]: {
            ...prevState.data[casingSize],
            [field]: fieldData,
          },
        },
      };
    });
  };

  const value = useMemo(() => ({ data, updateData, handleSaveFromImageViewer, setWellId }), [data]);

  return (
    <DataContext.Provider value={value}>
      {children}
    </DataContext.Provider>
  );
};

// Custom hook to use the DataContext
export const useData = () => {
  return useContext(DataContext);
};